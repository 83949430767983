import React from 'react';

const Logo = ({ width, height, fill }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
            <path d="M6.92435 2.03602C7.4524 1.90629 7.95877 2.25764 8.06155 2.76935C8.15585 3.23512 7.81355 3.67385 7.33453 3.79638C6.13907 4.09915 5.05669 4.71672 4.21077 5.57869C3.36485 6.44066 2.78941 7.51235 2.55005 8.67161C2.3107 9.83087 2.41705 11.0311 2.85698 12.1354C3.29692 13.2397 4.05274 14.2037 5.0383 14.9175C6.02386 15.6314 7.1995 16.0663 8.43122 16.1727C9.66295 16.2792 10.9012 16.0529 12.0048 15.5196C13.1085 14.9864 14.0331 14.1676 14.6733 13.1567C15.3136 12.1457 15.6436 10.9832 15.6259 9.80176C15.6344 9.35131 16.0031 8.95041 16.4699 8.90086C17.0385 8.8405 17.5184 9.2351 17.5128 9.78104L17.5118 9.89185C17.5118 9.98825 17.5099 10.0837 17.5062 10.1801C17.5109 10.2293 17.5109 10.2787 17.5062 10.3279L17.4986 10.4378C17.3516 12.5189 16.3708 14.4659 14.7601 15.8739C13.1493 17.2819 11.0326 18.0427 8.84986 17.9981C6.79794 17.9577 4.83089 17.2081 3.31301 15.8884C1.79513 14.5686 0.829256 12.768 0.594277 10.8201C0.359299 8.87216 0.871133 6.90887 2.03499 5.29382C3.19885 3.67877 4.93586 2.52139 6.92435 2.03602Z" fill={fill}/>
            <path d="M12.7311 10.5702C12.6203 11.1243 12.3749 11.6457 12.0148 12.0927C11.6546 12.5396 11.1897 12.8996 10.6574 13.1436C10.1251 13.3876 9.54033 13.5089 8.95006 13.4976C8.35979 13.4864 7.78052 13.3429 7.25885 13.0788C6.73717 12.8147 6.28765 12.4373 5.94645 11.977C5.60524 11.5167 5.38187 10.9863 5.2943 10.4285C5.20673 9.87066 5.2574 9.30099 5.44225 8.7653C5.62709 8.2296 5.94095 7.74282 6.35857 7.34412C7.06576 6.66827 8.02498 6.28847 9.02524 6.28827C9.73246 6.28827 10.2511 6.43241 10.9112 6.76575C10.9112 6.76575 11.0526 6.84683 11.1469 6.9189C11.0526 6.60358 10.9319 5.75133 10.9112 5.38737C10.8922 4.77644 10.8844 4.16524 10.8876 3.55404V0.846844C10.8876 0.378377 11.2949 0 11.7975 0C11.8947 0 11.9918 0.0144145 12.0851 0.0432432L16.3963 1.56486C16.8169 1.69549 16.9593 1.77207 17.0988 1.88288C17.2384 1.99279 17.3421 2.12612 17.41 2.28468C17.4788 2.44324 17.5118 2.59279 17.5118 3.0063V4.05134C17.5118 4.518 17.0941 4.89818 16.5924 4.89818C16.4947 4.89798 16.3977 4.88309 16.3048 4.85404L15.0045 4.45134L14.8621 4.40629C14.4501 4.28017 14.2794 4.22611 14.1191 4.17206C13.9544 4.11745 13.7927 4.05521 13.6344 3.98557C13.5663 3.95561 13.4993 3.92346 13.4335 3.88918C13.3411 3.84413 13.2101 3.77747 12.9423 3.65224L12.7971 3.58558L12.8074 9.89186C12.8074 10.1216 12.781 10.3486 12.7301 10.5702H12.7311Z" fill= {fill}/>
        </svg>
    );
};

export default Logo;