import React from 'react';


const Upload = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path d="M10.5256 0C4.72635 0 0.0256348 4.70137 0.0256348 10.5C0.0256348 16.2993 4.72635 21 10.5256 21C16.3243 21 21.0256 16.2993 21.0256 10.5C21.0256 4.70137 16.3243 0 10.5256 0ZM10.5256 19.6875C5.45153 19.6875 1.33816 15.5741 1.33816 10.5C1.33816 5.4259 5.45153 1.31252 10.5256 1.31252C15.5997 1.31252 19.7131 5.4259 19.7131 10.5C19.7131 15.5741 15.5997 19.6875 10.5256 19.6875Z" fill="white"/>
            <path d="M10.9949 4.1298C10.8715 4.00772 10.6989 3.93752 10.5256 3.93752C10.353 3.93752 10.1805 4.00838 10.0571 4.1298L5.46398 8.65726C5.20476 8.91387 5.20541 9.32926 5.46398 9.58521C5.7232 9.84181 6.14252 9.84181 6.40174 9.58521L9.86346 6.17269V16.4063C9.86346 16.7685 10.1601 17.0625 10.5263 17.0625C10.8925 17.0625 11.1891 16.7685 11.1891 16.4063V6.17334L14.6509 9.58587C14.9094 9.84181 15.3294 9.84181 15.588 9.58587C15.8465 9.32926 15.8465 8.91387 15.588 8.65792L10.9949 4.1298Z" fill="white"/>
        </svg>
    );
}

export default Upload;