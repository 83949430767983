import React from 'react';


const Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.4083 7.75832C14.3312 7.68107 14.2396 7.61978 14.1388 7.57796C14.038 7.53614 13.9299 7.51462 13.8208 7.51462C13.7116 7.51462 13.6036 7.53614 13.5028 7.57796C13.4019 7.61978 13.3104 7.68107 13.2333 7.75832L9.99994 10.9917L6.76661 7.75832C6.61079 7.60251 6.39946 7.51497 6.17911 7.51497C5.95875 7.51497 5.74742 7.60251 5.59161 7.75832C5.43579 7.91414 5.34826 8.12547 5.34826 8.34582C5.34826 8.56618 5.43579 8.77751 5.59161 8.93332L9.41661 12.7583C9.4937 12.8356 9.58528 12.8969 9.68609 12.9387C9.7869 12.9805 9.89497 13.002 10.0041 13.002C10.1132 13.002 10.2213 12.9805 10.3221 12.9387C10.4229 12.8969 10.5145 12.8356 10.5916 12.7583L14.4166 8.93332C14.7333 8.61665 14.7333 8.08332 14.4083 7.75832Z" fill="white" />
        </svg>
    );
}

export default Icon;